import React, { useState } from "react";
import Layout from "../components/Layout";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import "firebase/firestore";
import { useFirebaseApp, useFirestore, useUser, useAuth } from "reactfire";
import "firebase/auth";
import { navigate } from "gatsby";
import { useForm, useWatch } from "react-hook-form";
import { CountryDropdown } from "react-country-region-selector";
import InputPhone from "react-phone-number-input/input";

function Register() {
  const firebase1 = useFirebaseApp();
  const [countries, setCountries] = useState("");
  const [phone, setPhone] = useState();

  const [phoneError, setPhoneError] = useState(false);
  const [countryError, setCountryError] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const fieldValue = useFirestore.FieldValue;

  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    error: "",
    enquiry: "",
  });

  const firestore = useFirestore(firebase1);
  const auth = useAuth();
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    fname: "",
    email: "",
    password: "",
    confirmPassword: "",
    enquiry: "",
    contact: "",
  });

  const password1 = watch("Password", "");
  const confirmPassword1 = watch("confirmPassword");

  const onSubmit = async (data, e) => {
    e.preventDefault();
    console.log("Submitted Data: " + JSON.stringify(data));
    console.log("Registered: " + data.Contact);

    console.log("Selected COuntry: " + countries);

    if (phone && countries && confirmPassword1 == password1) {
      setPhoneError(false);
      setCountryError(false);

      setFormSubmitted(true);

      await firebase1
        .auth()
        .createUserWithEmailAndPassword(data.email, data.Password)
        .then((result) => {
          firestore
            .collection("Users")
            .doc()
            .set({
              name: data.fname,
              number: phone,
              email: data.email,
              enquiry: data.enquiry,
              country: countries,
              verified: "empty",
              timestamp: fieldValue.serverTimestamp(),
            })
            .then(function () {
              console.log("Document successfully written!");
              auth.signOut();
              navigate("/Login", { state: { newUser: "true" } });
            })
            .catch(function (error) {
              console.error("Error writing document: ", error);
            });
        })
        .catch((error) => {
          setUser({ ...user, error: error.message });
        });
    } else {
      if (!phone) {
        setPhoneError(true);
      }
      if (!countries) {
        setCountryError(true);
      }
    }
  };

  let emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleChange = (e) => {
    // switch (e.target.name) {
    //   case "name":
    //     if (e.target.value == "") {
    //       user.error = "Name cannot be empty";
    //     }
    //     break;

    //   case "number":
    //     if (e.target.value == "") {
    //       user.error = "Phone Number cannot be empty";
    //     } else if (e.target.value.length != 10) {
    //       user.error = "Phone number is invalid";
    //     }
    //     break;

    //   case "email":
    //     if (e.target.value == "") {
    //       user.error = "Email cannot be empty!";
    //     } else if (!emailRegex.test(e.target.value)) {
    //       user.error = "Email is not valid";
    //     }
    //     break;

    //   case "password":
    //     if (e.target.value == "") {
    //       user.error = "Password cannot be empty!";
    //     } else if (e.target.value.length < 6) {
    //       user.error = "Password length must be more than 6 characters";
    //     }
    //     break;

    //   case "enquiry":
    //     if (e.target.value == "") {
    //       user.error = "Please select enquiry type";
    //     } else if (e.target.value == "Enquiry") {
    //       user.error = "Invalid enquiry type";
    //     }
    //     break;

    //   case "Contact":
    //     if (e.target.value == "") {
    //       user.error = "Contact query cannot be empty";
    //     }
    //     break;
    // }

    // setValidated(true);
    setUser({ ...user, [e.target.name]: e.target.value, error: "" });
  };

  return (
    <Layout>
      <section className="product_section">
        <Container>
          <div>
            <h1>Register</h1>
            <hr />
          </div>
        </Container>
      </section>
      <section className="login_section">
        <Container>
          <Row>
            <Col sm="12" md={{ size: 6, offset: 3 }}>
              <Form className="contact_form" onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <input
                    type="text"
                    name="fname"
                    id="fname"
                    {...register("fname", {
                      required: "Name is required3",
                      minLength: 4,
                    })}
                    placeholder="Full Name"
                  />
                  {errors.fname &&
                    console.log("Error type: " + errors.fname.type)}

                  {errors.fname && errors.fname.type === "required" && (
                    <p className="errorMsg">Name is required.</p>
                  )}
                  {errors.fname && errors.fname.type === "minLength" && (
                    <p className="errorMsg">Minimum length of Name should 4.</p>
                  )}
                </FormGroup>
                <FormGroup row>
                  <Col sm={6}>
                    <CountryDropdown
                      value={countries}
                      valueType="short"
                      onChange={(val) => setCountries(val)}
                    />
                    {countryError && <p>Please select a country</p>}
                  </Col>
                  <Col sm={6}>
                    <InputPhone
                      country={countries}
                      international
                      withCountryCallingCode={true}
                      name="contact"
                      placeholder="Contact No"
                      onChange={setPhone}
                      className="inpout_phone"
                    />

                    {phoneError && <p>Phone number is required</p>}

                    {/* {errors.phone && errors.phone.type === "required" && (
                      <p className="errorMsg">Phone number is required.</p>
                    )}
                    {errors.phone && errors.phone.type === "minLength" && (
                      <p className="errorMsg">
                        Phone number should be of 10 digits.
                      </p>
                    )} */}
                  </Col>
                </FormGroup>
                <FormGroup>
                  <input
                    type="email"
                    name="email"
                    {...register("email", {
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address",
                      },
                    })}
                    placeholder="Email"
                  />
                  {errors.email && errors.email.type === "required" && (
                    <p className="errorMsg">Email is required.</p>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <p className="errorMsg">
                      Please enter a valid email address.
                    </p>
                  )}
                </FormGroup>
                <FormGroup>
                  <input
                    type="password"
                    name="Password"
                    {...register("Password", {
                      required: true,
                      minLength: 6,
                    })}
                    placeholder="Password"
                  />
                  {errors.Password && errors.Password.type === "required" && (
                    <p className="errorMsg">Password is required.</p>
                  )}
                  {errors.Password && errors.Password.type === "minLength" && (
                    <p className="errorMsg">
                      Password must be above 6 characters.
                    </p>
                  )}
                </FormGroup>
                <FormGroup>
                  {/* <input
                    type="password"
                    name="confromPassword"
                    onChange={handleChange}
                    {...register("Password", {
                      required: true,
                      minLength: 6,
                      maxLength: 25,
                    })}
                    placeholder="Conform Password"
                  /> */}
                </FormGroup>
                <FormGroup>
                  <input
                    type="password"
                    name="confirmPassword"
                    {...register("confirmPassword", {
                      required: true,
                      minLength: 6,
                      maxLength: 25,
                    })}
                    placeholder="Confirm Password"
                  />

                  {confirmPassword1 != undefined &&
                    confirmPassword1 != password1 && (
                      <p className="errorMsg">Passwords must match.</p>
                    )}

                  {errors.confirmPassword &&
                    errors.confirmPassword.type === "required" && (
                      <p className="errorMsg">Password is required.</p>
                    )}
                  {errors.confirmPassword &&
                    errors.confirmPassword.type === "minLength" &&
                    errors.confirmPassword.type === "maxLength" && (
                      <p className="errorMsg">
                        Password must be above 6 characters.
                      </p>
                    )}
                </FormGroup>
                <FormGroup>
                  <select
                    type="select"
                    name="enquiry"
                    {...register("enquiry", {
                      required: true,
                    })}
                  >
                    <option value="Enquiry">Enquiry</option>
                    <option value="End User">End User</option>
                    <option value="Reseller">Reseller</option>
                  </select>
                  {errors.enquiry && errors.enquiry.type === "required" && (
                    <p className="errorMsg">Enquiry is required.</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <textarea
                    name="contact"
                    placeholder="Your Message"
                    {...register("contact", {
                      required: true,
                    })}
                  />
                  {errors.contact && errors.contact.type === "required" && (
                    <p className="errorMsg">Your message is required.</p>
                  )}
                </FormGroup>
                {formSubmitted ? (
                  <Button
                    type="submit"
                    className="login_btn"
                    disabled={formSubmitted}
                  >
                    Please Wait...
                  </Button>
                ) : (
                  <Button type="submit" className="login_btn" disabled={false}>
                    Register
                  </Button>
                )}
              </Form>
              {user.error && <p style={{ color: "red" }}>{user.error}</p>}
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}

export default Register;
